<template>
  <div :class="`shadow p-5 my-5 bg-white rounded ${isMobile ? 'w-100' : 'w-50'}`">
    <p class="font-bold font-20">{{ $t('change_password') }}</p>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-form-group :label="$t('current_password')">
        <b-input-group>
          <b-form-input
            class="form-control form-control-solid p-2"
            :type="showCurPassword ? 'text' : 'password'"
            v-model="$v.form.curPassword.$model"
            :state="validateState('curPassword')"
          />
          <template v-slot:append>
            <v-btn icon @click="showCurPassword = !showCurPassword">
              <v-icon>{{ showCurPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-btn>
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('new_password')">
        <b-input-group>
          <b-form-input
            class="form-control form-control-solid p-2"
            :type="showNewPassword ? 'text' : 'password'"
            v-model="$v.form.newPassword.$model"
            :state="validateState('newPassword')"
          />
          <template v-slot:append>
            <v-btn icon @click="showNewPassword = !showNewPassword">
              <v-icon>{{ showNewPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-btn>
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('confirm_password')">
        <b-input-group>
          <b-form-input
            class="form-control form-control-solid p-2"
            :type="showConfirmPassword ? 'text' : 'password'"
            v-model="$v.form.confirmPassword.$model"
            :state="validateState('confirmPassword')"
          />
          <template v-slot:append>
            <v-btn icon @click="showConfirmPassword = !showConfirmPassword">
              <v-icon>{{ showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-btn>
          </template>
        </b-input-group>
      </b-form-group>

      <!--begin::Action-->
      <div class="w-100" align="end">
        <button class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
          {{ $t('modify') }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>


<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { firebaseAuth } from '../../../main';
import { showLoading } from '../../../functions';
import { signInWithEmailAndPassword, updatePassword, signOut } from 'firebase/auth';

export default {
  mixins: [validationMixin],
  name: "ChangePassword",
  validations: {
    form: {
      curPassword: {
        required,
        minLength: minLength(6)
      },
      newPassword: {
        required,
        minLength: minLength(6)
      },
      confirmPassword: {
        required,
        minLength: minLength(6)
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      form: {
        curPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      showCurPassword: false,
      showNewPassword: false,
      showConfirmPassword: false
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const curPassword = this.$v.form.curPassword.$model;
      const newPassword = this.$v.form.newPassword.$model;
      const confirmPassword = this.$v.form.confirmPassword.$model;
      if (newPassword !== confirmPassword) {
        this.$toast.error(this.$t('password_does_not_match'));
        return;
      }

			const loader = showLoading(this, 1);
			signInWithEmailAndPassword(firebaseAuth, firebaseAuth.currentUser.email, curPassword)
			.then(response1 => {
				const currentUser = response1.user;
				if (currentUser) {
					updatePassword(currentUser, newPassword)
					.then(response2 => {
						loader.hide();
						this.$toast.success(this.$t('admin_password_changed_login_again'));
            signOut(firebaseAuth);
					}).catch(error1 => {
						loader.hide();
						this.$toast.error(error1.code + ', ' + error1.message);
					});
				} else {
					loader.hide();
					this.$toast.error('Unknown error occurs.');
				}
			}).catch(error2 => {
				loader.hide();
				this.$toast.error(this.$t('current_password_incorrect'));
			});
    }
  }
};
</script>
